.djs-minimap {
  top: auto;
  bottom: 80px;
  right: 20px;
}

.djs-minimap .toggle {
  display: none;
}

.bjs-powered-by {
  visibility: hidden;
}


.bpmn-icon-task.red {
  color: #cc0000 !important;
}

.bpmn-icon-task.yellow {
  color: #ffc800 !important;
}

.bpmn-icon-task.green {
  color: #52B415 !important;
}

#quality-assurance {
  color: #111;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

#quality-assurance #form input {
  border: solid 1px #ccc;
  border-radius: 2px;
  font-family: 'Arial', sans-serif;
  padding: 10px;
}

#quality-assurance #form input[type=text] {
  width: 100%;
}

#quality-assurance #form #warning {
  background-color: rgba(255, 0, 0, 0.25);
  border-radius: 2px;
  padding: 10px;
}

#quality-assurance #form input[type=submit] {
  background-color: #FAFAFA;
  color: #111;
}

#hint {
  bottom: 20px;
  left: 20px;
  position: absolute;
}
